.carouselItem {
    padding-top: 0;
    padding-bottom: 25px;
}

.carouselItem > img {
    position: relative;
    top: -30px;
    padding-bottom: 60px;
}

.carouselCaption {
    display: flex;
    justify-content: center;
}
