/* ################################################## Containers ################################################## */
.container-fluid {
    padding: 5% 15%;
}

/* ################################################## Sections ################################################## */
.section-heading {
    line-height: 1.5;
}

.colored-section {
    background-color: #ff7d4b;
    color: #ffffff;
}
.white-section {
    background-color: #ffffff;
    color: #000000;
}

/* ################################################## Hero Section ################################################## */
.hero {
    background-image: linear-gradient(to bottom right, #ffffff, #72a544);
}

.banner-texts {
    position: relative;
    top: 100px;
    left: 50px;
}

.big-heading {
    color: #ffffff;
    font-size: 2.5rem;
    font-weight: 400;
    letter-spacing: 0.1rem;
    line-height: 1.5;
    text-shadow: 0.75px 0 0 #72a544, 0 1.5px 0 #72a544, 0 0 5px #72a544;
}

.banner-paragraph {
    color: #ffffff;
    font-weight: 500;
    letter-spacing: 0.15rem;
    font-size: 1.1rem;
    line-height: 2;
    text-shadow: 0.75px 0 0 #72a544, 0 1.5px 0 #72a544, 0 0 5px #72a544;
}

.hero-p-icon {
    color: #ff7d4b;
}
.hero-p-icon:hover {
    color: #d8351f;
}

.title-image {
    max-width: 420px;
    width: 25%;
    min-width: 300px;
    transform: rotate(-30deg);
    position: absolute;
    top: 15%;
    right: 15%;
    z-index: 1;
}

/* ################################################## Title Section ################################################## */
#title .container-fluid {
    padding: 3% 15% 7%;
    text-align: left;
}

/* ################################################## Features Section ################################################## */
#features {
    position: relative;
}
.feature-title {
    font-size: 1, 5rem;
}
.feature-box {
    padding: 5%;
}
.features-img {
    color: #72a544;
    margin-bottom: 1rem;
}
.features-img:hover {
    color: #ff4e00;
}
.features-h3 {
    font-family: "Montserrat-Bold", sans-serif;
}
.features-p {
    color: #8f8f8f;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    filter: invert(1);
}

/* ################################################## Hibiscus Section ################################################## */
#hibiscus {
    background-color: #72a544;
}
.hibiscus-text {
    font-family: "Dancing Script", cursive;
    font-size: 3.5rem;
    margin-top: 25px;
}
.hibiscus-image {
    width: 100%;
    max-width: 350px;
}

.hibiscus-head {
    padding: 20px;
}
.hibiscus-head h3 {
    font-family: "Dancing Script", cursive;
    font-size: 2rem;
    line-height: 1.25;
}

/* ################################################## Mandevilla Section ################################################## */
#mandevilla {
    background-color: #ffffff;
}
.mandevilla-text {
    font-family: "Dancing Script", cursive;
    font-size: 3.5rem;
    margin-top: 20px;
    color: #000000;
}
.mandevilla-image {
    width: 100%;
    max-width: 350px;
    /* border-radius: 100%; */
}

.mandevilla-head {
    padding: 20px;
}
.mandevilla-head h3 {
    font-family: "Dancing Script", cursive;
    font-size: 2rem;
    line-height: 1.25;
}

/* ################################################## Carousel ################################################## */
/* .carousel-item {
    padding-top: 0;
    padding-bottom: 25px;
} */

/* ################################################## Products Section ################################################## */
#products {
    padding: 5%;
    color: #ffffff;
    background-color: #ff6d37;
}
#products h3 {
    font-size: 2.5rem;
}
.products-text {
    font-size: 2rem;
    line-height: 1;
}
.products-column {
    padding: 3% 2%;
}
.card {
    background-color: #ff6d37;
}

/* ################################################## Partners Section ################################################## */
#partners {
    background-color: #ffffff;
    padding-top: 2%;
}
.partners-logo {
    width: 150px;
    margin: 20px 20px 50px;
}

/* ################################################## Footer ################################################## */
#footer {
    background-image: linear-gradient(to bottom right, #ff6d37, #000000);
}

#footer .container-fluid {
    padding-top: 25px;
    padding-bottom: 5px;
}

#footer .container-fluid .googleMap {
    width: 600px;
    height: 300px;
    border: 5px solid #ff7d4b;
    border-radius: 25px;
    margin-bottom: 15px;
}

#footer .container-fluid a {
    text-decoration: none;
    color: #ffffff;
}

#footer .container-fluid a:hover {
    color: rgb(250, 176, 61);
}

@media (max-width: 1028px) {
    #title {
        text-align: center;
    }
    .title-image {
        position: static;
        transform: rotate(0);
    }

    .banner-texts {
        position: unset;
    }

    #hibiscus,
    #mandevilla,
    #partners {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    /* #footer .container-fluid .googleMap {
        width: 300px;
    } */

    #footer .container-fluid {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
