.text {
    padding-top: 15px;
}

.text > span {
    line-height: 35px;
}

.button {
    background-color: transparent;
    border: transparent;
    padding: 0;
}

.button:hover {
    background-color: transparent;
}

.mainPerk {
    display: inline-block;
    font-size: 2rem;
    margin-bottom: 10px;
}
