/* Navigation Bar */
.navBar {
    background-color: transparent;
    z-index: 99999;
}
.navLogo img {
    max-width: 50%;
    min-width: 200px;
}

.navList {
    gap: 15px;
}

.navLink {
    text-align: center;
    position: relative;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 1.1rem;
    letter-spacing: 0.3rem;
    color: #ff7d4b !important;
    text-decoration: none;
    padding: 0 10px;
}

.navLink:hover {
    color: #d8351f !important;
}

.navLink::after {
    position: absolute;
    content: "";
    background-color: #d8351f !important;
    height: 0.15rem;
    width: 0;
    left: 5%;
    bottom: -5px;
    transition: 0.2s;
}

.navLink:hover::after {
    width: 85%;
}

@media (max-width: 768px) {
    .navList {
        padding: 15px;
    }

    .navLink {
        text-align: left;
        width: fit-content;
        margin-bottom: 10px;
    }

    .navLogo img {
        max-width: 100%;
    }
}
